<template>
  <main class="mx-auto max-w-screen-2xl px-4 py-2 overflow-y-auto mb-32 sm:mb-20">
    <header class="mb-4 flex flex-wrap items-baseline justify-between">
      <h1 class="my-1 text-2xl font-bold">Occupants</h1>
      <p class="text-sm">
        Three parameters determine the occupancy level of a space: <strong>peak occupancy</strong>, <strong>occupant duration</strong> and <strong>occupant schedule</strong>.
      </p>
    </header>

    <dynamic-form :form="form" @change="valueChanged" class="mt-4 md:mt-6 p-4 md:p-6 bg-gray-100 rounded-lg">
      <!-- Peak -->
      <template v-slot:peakOccupancy="{ control, onChange, onFocus, onBlur }">
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="peak" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2">
            Occupancy
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <input
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              class="w-48 max-w-xs shadow-sm focus:ring-pink-500 focus:border-pink-500 block sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              type="number"
              min=1
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
            >
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">The maximum number of people that typically occupy the space under normal operating conditions. Once a simulation is complete, the user may adjust to current occupancy by selecting an appropriate occupancy configuration. <a href="https://help.particleone.com/peak-occupancy-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg></a></div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>

        <!-- Duration -->
      <template v-slot:occupantDurationMinutes="{ control, onChange, onFocus, onBlur }">
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="duration" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal whitespace-normal">
            Occupancy duration (minutes)
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 w-48 max-w-xs">
            <select
              autocomplete=""
              class="block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
            >
              <option disabled selected value=undefined>Please select</option>
              <option v-for="option in occupantDurationOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">The amount of time a typical occupant will be in this space.</div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>

      <template v-slot:occupantSchedule="{ control, onChange, onFocus, onBlur }">
        <!-- Schedule -->
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="schedule" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal">
            Occupant schedule
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <select
              autocomplete=""
              class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
            >
              <option disabled selected value=undefined>Please select</option>
              <option v-for="option in occupancySchedule" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">The schedule that best reflects the hour-by-hour breakdown of the percentage of occupancy for this space. <a href="https://help.particleone.com/occupant-schedule-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg></a> about the different occupant schedule options.</div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>


      <template v-slot:occupantProfile="{ control, onChange, onFocus, onBlur }">
        <!-- Profile -->
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="profile" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal">
            Occupant profile
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <select
              autocomplete=""
              class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
            >
              <option disabled selected value=undefined>Please select</option>
              <option v-for="option in occupantProfile" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">The description that reflects the majority of occupants in the space.</div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>



         <!-- Activity -->
      <template v-slot:activity="{ control, onChange, onFocus, onBlur }">
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="activity" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2">
            Activity
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <select
              autocomplete=""
              class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
            >
              <option disabled selected value=undefined>Please select</option>
              <option v-for="option in activity" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">
            The level of activity most typical for occupants within this space. <a href="https://help.particleone.com/activity/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg></a>
          </div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>



        <!-- Mask -->
      <template v-slot:maskType="{ control, onChange, onFocus, onBlur }">
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="mask" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2">
            Mask type
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
              <select
                autocomplete=""
                class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                :id="control.name"
                v-if="control"
                v-model="control.value"
                :name="control.name"
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
              >
              <option disabled selected value=undefined>Please select</option>
              <option v-for="option in maskType" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">
            Typical mask type worn by occupants in this space.
          </div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>

      <template v-slot:maskUse="{ control, onChange, onFocus, onBlur }">
        <!-- Mask use - ONLY DISPLAY IF MASK TYPE IS NOT 'NONE' -->
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="duration" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3">
            Mask use
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs">
              <input
                type="number"
                class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder=""
                aria-describedby=""
                :id="control.name"
                v-if="control"
                v-model="control.value"
                :name="control.name"
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
              >
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-600 sm:text-sm" id="">
                  %
                </span>
              </div>
            </div>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">
            Percentage of time a typical occupant is wearing a mask in this space.
            </div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>


      <template v-slot:socialDistancing="{ control, onChange, onFocus, onBlur }">
        <!-- Social distancing -->
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="social-distancing" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5 whitespace-normal">
            Social distancing
          </label>

          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
            <Switch
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
              :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
            >
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
            </Switch>
            <span class="ml-3" id="">
              <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
            </span>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">
            Occupants are at least 2 metres (6 feet) apart in this space.
          </div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>

      <template v-slot:enhancedCleaning="{ control, onChange, onFocus, onBlur }">
      <!-- Enhanced Cleaning -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="enhanced-cleaning" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5 whitespace-normal">
          Enhanced cleaning
        </label>

        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
          <Switch
          :id="control.name"
          v-if="control"
          v-model="control.value"
          :name="control.name"
          @change="onChange"
          @focus="onFocus"
          @blur="onBlur"
          :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
          >
          <span class="sr-only">Use setting</span>
          <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
          <span class="ml-3" id="">
          <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
          </span>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Choose “yes” if a systematic program for enhanced cleaning is in place. Choose “no” if cleaning occurs on an irregular schedule, 
          or only periodically in response to exposures, incidents, or is dependent on availability of cleaning supplies.
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:vaccineMandate="{ control, onChange, onFocus, onBlur }">
      <h2 class="block pt-6 md:pb-6 mb-2 text-lg text-gray-900 font-bold border-t-2">COVID-19</h2>
      <!-- Vaccine mandate -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="vaccine-mandate" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5 whitespace-normal">
          COVID <span class="font-bold">Fully Vaccinated</span> mandate
        </label>

        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
          <Switch
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
            :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
          >
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
          <span class="ml-3" id="">
            <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
          </span>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Occupants are required to show proof of being Fully Vaccinated. <a href="https://help.particleone.com/vaccination-data-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg></a>
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:backgroundVaccinationRate="{ control, onChange, onFocus, onBlur }">
      <!-- Vaccination data - ONLY DISPLAY IF VACCINE MANDATE IS 'NO' -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="vaccination-data" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal">
          COVID <span class="font-bold">Fully Vaccinated</span> data
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <select
            autocomplete=""
            class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
          >
            <option selected value="default">Province / State</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Province/State community or custom rates of Fully Vaccinated occupants.
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:vaccinationPercent="{ control, onChange, onFocus, onBlur }">
      <!-- Vaccination % - ONLY DISPLAY IF VACCINE MANDATE IS 'NO' AND 'VACCINATION DATA' IS CUSTOM -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="duration" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3 whitespace-normal">
          COVID <span class="font-bold">Fully Vaccinated</span> occupant data
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs">
            <input
              type="number"
              class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby=""
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
              min="1"
              max="100"
            >
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-600 sm:text-sm" id="">
                %
              </span>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Custom rate entry
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:vaccineMandateBooster="{ control, onChange, onFocus, onBlur }">
        <!-- COVID “Boostered” vaccine mandate -->
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="vaccine-mandate" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5 whitespace-normal">
            COVID <span class="font-bold">Boostered</span> mandate
          </label>

          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
            <Switch
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
              :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
            >
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
            </Switch>
            <span class="ml-3" id="">
              <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
            </span>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">
            Occupants are required to show proof of being Boostered. <a href="https://help.particleone.com/vaccination-data-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg></a>
          </div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>

      <template v-slot:backgroundVaccinationRateBooster="{ control, onChange, onFocus, onBlur }">
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="vaccination-data" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal">
          COVID <span class="font-bold">Boostered</span> vaccination data
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <select
            autocomplete=""
            class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
          >
            <option selected value="default">Province / State</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Province/State Community or custom rates of “Boostered” occupants.        
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:vaccinationPercentBooster="{ control, onChange, onFocus, onBlur }">
      <!-- COVID 'Boostered' Vaccination % - ONLY DISPLAY IF COVID 'Boostered' mandate IS 'NO' AND COVID 'Boostered' vaccination data IS CUSTOM -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="duration" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3 whitespace-normal">
          Occupant <span class="font-bold">Boostered</span> vaccination data
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs">
            <input
              type="number"
              class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby=""
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
              min="1"
              max="100"
            >
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-600 sm:text-sm" id="">
                %
              </span>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Custom rate entry
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>
    
    <template v-slot:testingProgram="{ control, onChange, onFocus, onBlur }">
      <!-- Testing program -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="testing-program" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5 whitespace-normal">
          Testing program
        </label>

        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
          <Switch
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
            :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
          >
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
          <span class="ml-3" id="">
            <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
          </span>
        </div>
          <div class="sm:col-span-3 text-sm text-gray-600">
            Choose “yes” if a systematic testing program for proactive screening is in place. Choose “no” if testing is only periodic in response to exposures, an outbreak, or is dependent on availability of tests. <a href="https://help.particleone.com/testing-program-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg></a>
          </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:testingTechnology="{ control, onChange, onFocus, onBlur }">
      <!-- Testing technology - ONLY DISPLAY IF TESTING PROGRAM IS 'YES' -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="testing-technology" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal">
          Testing technology
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <select
            autocomplete=""
            class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
          >
            <option disabled selected value=undefined>Please select</option>
            <option v-for="option in testingTechnologies" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600"></div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:testingProgramType="{ control, onChange, onFocus, onBlur }">
      <!-- Program type - ONLY DISPLAY IF TESTING PROGRAM IS 'YES' -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="program-type" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal">
          Program type
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <select
            autocomplete=""
            class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
          >
            <option disabled selected value=undefined>Please select</option>
            <option v-for="option in testingProgramTypes" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:testingCadence="{ control, onChange, onFocus, onBlur }">
      <!-- Testing cadence - ONLY DISPLAY IF TESTING PROGRAM IS 'YES' -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="testing-cadence" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal">
          Testing cadence
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <select
            autocomplete=""
            class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
          >
            <option disabled selected value=undefined>Please select</option>
            <option v-for="option in testingCadences" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600"></div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:testingUnvaccinated="{ control, onChange, onFocus, onBlur }">
      <!-- Testing for unvaccinated only - ONLY DISPLAY IF TESTING PROGRAM IS 'YES' -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="testing-unvaccinated" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5 whitespace-normal">
          Testing for unvaccinated only
        </label>

        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
          <Switch
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
            :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
          >
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
          <span class="ml-3" id="">
            <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
          </span>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div>
      </div>
    </template>
    
    <template v-slot:vaccineMandateFlu="{ control, onChange, onFocus, onBlur }">
      <!-- Influenza vaccine mandate -->
      <h2 class="block pt-6 md:pb-6 mb-2 text-lg text-gray-900 font-bold border-t-2">Influenza</h2>
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="vaccine-mandate-flu" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5 whitespace-normal">
          Influenza vaccinated mandate
        </label>

        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
          <Switch
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
            :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
          >
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
          <span class="ml-3" id="">
            <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
          </span>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Occupants are required to show proof of being vaccinated against influenza. <a href="https://help.particleone.com/vaccination-data-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg></a>
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:backgroundVaccinationRateFlu="{ control, onChange, onFocus, onBlur }">
      <!-- Influenza vaccination data - ONLY DISPLAY IF Influenza vaccinated mandate IS 'NO' -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="vaccination-data" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal">
          Influenza vaccination data
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <select
            autocomplete=""
            class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
          >
            <option selected value="default">Province / State</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Province/State community or custom rates of influenza vaccination.
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:vaccinationPercentFlu="{ control, onChange, onFocus, onBlur }">
      <!-- Influenza Vaccination % - ONLY DISPLAY IF Influenza vaccinated mandate IS 'NO' AND 'VACCINATION DATA' IS CUSTOM -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="duration" class="sm:col-span-1 block text-sm font-medium text-gray-80 sm:pt-3 whitespace-normal">
          Influenza occupant data
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs">
            <input
              type="number"
              class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby=""
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
              min="1"
              max="100"
            >
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-600 sm:text-sm" id="">
                %
              </span>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Custom rate entry
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

  </dynamic-form>

  </main>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';
import { CustomField } from '@asigloo/vue-dynamic-forms';
import { Switch } from '@headlessui/vue';
import {
  ParticleOneActivity,
  ParticleOneMaskType,
  ParticleOneOccupancySchedule,
  ParticleOneOccupantProfile,
  ParticleOneTestingCadence,
  ParticleOneTestingProgram,
  ParticleOneTestingTechnology
} from '@/network/models/ParticleOneCreateSpaceRequest';
import { validations } from '@/models/create-space-mapping';

export default {
  name: 'SpaceFormPageThree',
  components: {
    Switch
  },
  computed: {
    occupantDurationOptions() {
      return [2,5,10,15,30,45,60,90,120,180,240,300,360,420,480,540,600,660,720,840,960,1080,1200,1320,1440]
        .map(value => ({ value: value, label: `${value} min` }));
    },
    occupancySchedule() {
      return Object.entries(ParticleOneOccupancySchedule).map(([key, value]) => ({ label: key, value: value }));
    },
    occupantProfile() {
      return Object.entries(ParticleOneOccupantProfile).map(([key, value]) => ({ label: key, value: value }));
    },
    activity() {
      return Object.entries(ParticleOneActivity).map(([key, value]) => ({ label: key, value: value }));
    },
    maskType() {
      return Object.entries(ParticleOneMaskType).map(([key, value]) => ({ label: key, value: value }));
    },
    testingTechnologies() {
      return Object.entries(ParticleOneTestingTechnology).map(([key, value]) => ({ label: key, value: value }));
    },
    testingProgramTypes() {
      return Object.entries(ParticleOneTestingProgram).map(([key, value]) => ({ label: key, value: value }));
    },
    testingCadences() {
      return Object.entries(ParticleOneTestingCadence).map(([key, value]) => ({ label: key, value: value }));
    }
  },
  props: {
    currentSpace: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const formValues = reactive({});
    const initialValues = {
      peakOccupancy: props.currentSpace.peak_occupancy,
      occupantDurationMinutes: props.currentSpace.occupant_duration_minutes ?? undefined,
      occupancySchedule: props.currentSpace.occupant_schedule ?? undefined,
      occupantProfile: props.currentSpace.occupant_profile ?? undefined,
      activity: props.currentSpace.activity ?? undefined,
      maskType: props.currentSpace.mask_type ?? undefined,
      maskUse: props.currentSpace.mask_use_rate,
      socialDistancing: props.currentSpace.social_distancing ?? false,
      enhancedCleaning: props.currentSpace.enhanced_cleaning ?? false,
      vaccineMandate: props.currentSpace.vaccine_mandate ?? false,
      backgroundVaccinationRate: (props.currentSpace.background_vaccination_rate === 100 || props.currentSpace.background_vaccination_rate == null ? 'default' : 'custom'),
      vaccinationPercent: props.currentSpace.background_vaccination_rate,
      vaccineMandateBooster: props.currentSpace.vaccine_mandate_booster ?? false,
      backgroundVaccinationRateBooster: (props.currentSpace.background_vaccination_rate_booster === 100 || props.currentSpace.background_vaccination_rate_booster == null ? 'default' : 'custom'),
      vaccinationPercentBooster: props.currentSpace.background_vaccination_rate_booster,
      testingProgram: props.currentSpace.testing_program ?? false,
      testingTechnology: props.currentSpace.testing_technology ?? undefined,
      testingProgramType: props.currentSpace.program ?? undefined,
      testingCadence: props.currentSpace.testing_cadence ?? undefined,
      testingUnvaccinated: props.currentSpace.testing_unvaccinated_only ?? false,
      vaccineMandateFlu: props.currentSpace.vaccine_mandate_flu ?? false,
      backgroundVaccinationRateFlu: (props.currentSpace.background_vaccination_rate_flu === 100 || props.currentSpace.background_vaccination_rate_flu == null ? 'default' : 'custom'),
      vaccinationPercentFlu: props.currentSpace.background_vaccination_rate_flu
    };

    const form = computed(() => ({
      id: 'space-creation-page-three',
      fields: {
        peakOccupancy: CustomField({
          value: initialValues.peakOccupancy
        }),
        occupantDurationMinutes: CustomField({
          value: initialValues.occupantDurationMinutes
        }),
        occupantSchedule: CustomField({
          value: initialValues.occupancySchedule
        }),
        occupantProfile: CustomField({
          value: initialValues.occupantProfile
        }),
        activity: CustomField({
          value: initialValues.activity
        }),
        maskType: CustomField({
          value: initialValues.maskType
        }),
        maskUse: CustomField({
          value: initialValues.maskUse,
          customClass: `${formValues.maskType === ParticleOneMaskType.None ? 'hidden' : 'visible'}`
        }),
        socialDistancing: CustomField({
          value: initialValues.socialDistancing
        }),
        enhancedCleaning: CustomField({
          value: initialValues.enhancedCleaning
        }),
        vaccineMandate: CustomField({
          value: initialValues.vaccineMandate
        }),
        backgroundVaccinationRate: CustomField({
          value: initialValues.backgroundVaccinationRate,
          customClass: `${formValues.vaccineMandate ? 'hidden' : 'visible'}`
        }),
        vaccinationPercent: CustomField({
          value: initialValues.vaccinationPercent,
          customClass: `${formValues.vaccineMandate === false && formValues.backgroundVaccinationRate === 'custom' ? 'visible' : 'hidden'}`
        }),
        vaccineMandateBooster: CustomField({
          value: initialValues.vaccineMandateBooster,
          customClass: `${formValues.vaccineMandate  === false ? 'hidden' : 'visible'}`
        }),
        backgroundVaccinationRateBooster: CustomField({
          value: initialValues.backgroundVaccinationRateBooster,
          customClass: formValues.backgroundVaccinationRate === 'default' ? 'hidden' : `${formValues.vaccineMandateBooster ? 'hidden' : 'visible'}`
        }),
        vaccinationPercentBooster: CustomField({
          value: initialValues.vaccinationPercentBooster,
          customClass: `${formValues.vaccineMandateBooster === false && formValues.backgroundVaccinationRateBooster === 'custom' ? 'visible' : 'hidden'}`
        }),
        testingProgram: CustomField({
          value: initialValues.testingProgram
        }),
        testingTechnology: CustomField({
          value: initialValues.testingTechnology,
          customClass: `${formValues.testingProgram ? 'visible' : 'hidden'}`
        }),
        testingProgramType: CustomField({
          value: initialValues.testingProgramType,
          customClass: `${formValues.testingProgram ? 'visible' : 'hidden'}`
        }),
        testingCadence: CustomField({
          value: initialValues.testingCadence,
          customClass: `${formValues.testingProgram && formValues.testingProgramType !== ParticleOneTestingProgram['One-time'] ? 'visible' : 'hidden'}`
        }),
        testingUnvaccinated: CustomField({
          value: initialValues.testingUnvaccinated,
          customClass: `${formValues.testingProgram ? 'visible' : 'hidden'}`
        }),
        vaccineMandateFlu: CustomField({
          value: initialValues.vaccineMandateFlu
        }),
        backgroundVaccinationRateFlu: CustomField({
          value: initialValues.backgroundVaccinationRateFlu,
          customClass: `${formValues.vaccineMandateFlu ? 'hidden' : 'visible'}`
        }),
        vaccinationPercentFlu: CustomField({
          value: initialValues.vaccinationPercentFlu,
          customClass: `${formValues.vaccineMandateFlu === false && formValues.backgroundVaccinationRateFlu === 'custom' ? 'visible' : 'hidden'}`
        })
      }
    }));

    function valueChanged(values) {
      /* If "COVID Fully Vaccinated mandate" is toggled to false,
      make sure Boostered mandate is also set to false */
      if (!values.vaccineMandate) {
        values.vaccineMandateBooster = false;
      }
      /* If users choose to use province / state data 
       for their "COVID Fully Vaccinated percentage,
       force Booster percentage to use province / state data */
      if (values.backgroundVaccinationRate === 'default') {
        values.backgroundVaccinationRateBooster = 'default';
      }
      Object.assign(formValues, values);
      context.emit('formValues', formValues);
    }

    return {
      form,
      formValues,
      valueChanged,
      validations
    };
  }
};
</script>